<template>
	<div v-if="!logged_user.id" row style="justify-content: center;">
		<LoginFields
			:custom_login='true'
			:brand='brand'
		/>
	</div>
</template>
<script>
import LoginFields from "@/components/login/LoginFields.vue";
import { mapGetters } from "vuex";
export default ({
	name: 'CustomLogin',
	meta: {
		title: 'Alert Cascade - Login'
	},
	components: {
		LoginFields,
	},
	data() {
		return {
			brand: '',
		}
	},
	computed: {
		...mapGetters(["logged_user"]),
	},
	async created() {
		this.brand = window.location.pathname.split('/').pop()
	}
})
</script>
